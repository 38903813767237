<template>
  <div class="picture-showcase">
    <el-carousel
      arrow="always"
      :autoplay="false"
      indicator-position="none"
      height="380px"
      ref="carousel"
    >
      <el-carousel-item v-for="(item, index) in images" :key="index">
        <img
          width="298px"
          height="300px"
          :src="$store.state.onlineBasePath + item.fileUrl"
          alt=""
        />
        <div class="carousel" @click="magnifier(item)">
          <img
            width="30px"
            height="30px"
            src="../../assets/images/magni-fier.png"
            alt=""
          />
        </div>
      </el-carousel-item>
      <ul class="mycarousel-indicators">
        <li
          class="mycarousel-indicator"
          v-for="(item, index) in images"
          :key="index"
          @click="setActiveIndex(index)"
          v-show="index < 4"
        >
          <img
            width="60px"
            height="60px"
            :src="$store.state.onlineBasePath + item.fileUrl"
            alt=""
          />
        </li>
      </ul>
    </el-carousel>
    <el-dialog
      id="addFiles"
      :visible.sync="addFilesPopup"
      @close="closeAddpopup"
    >
      <img
        width="800px"
        :src="$store.state.onlineBasePath + imageItem"
        alt=""
      />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PictureShowcase",
  props: {
    images: {
      default: () => [],
    },
  },
  data() {
    return {
      // 查看图片弹框
      addFilesPopup: false,
      imgList: this.images,
      imageItem: "",
    };
  },
  methods: {
    // 图片弹框取消
    closeAddpopup() {
      this.addFilesPopup = false;
    },
    // 放大图片
    magnifier(item) {
      this.imageItem = item.fileUrl;
      this.addFilesPopup = true;
      console.log("111", item);
    },
    setActiveIndex(index) {
      this.$refs["carousel"].setActiveItem(index);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__arrow.el-carousel__arrow--left {
  top: 350px;
  width: 17px;
  height: 29px;
  left: 0px;
  background: none;
  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 17px;
    height: 29px;
    background: url("~assets/images/detail_slide_left.png") no-repeat;
    background-size: 100% 100%;
  }
  i::before {
    content: "";
  }
}
::v-deep .el-carousel__arrow.el-carousel__arrow--right {
  top: 350px;
  width: 17px;
  height: 29px;
  right: 0px;
  background: none;
  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 17px;
    height: 29px;
    background: url("~assets/images/detail_slide_right.png") no-repeat;
    background-size: 100% 100%;
  }
  i::before {
    content: "";
  }
}
.picture-showcase {
  position: relative;
  width: 300px;
  img {
    border: 1px solid #e9e9e9;
  }
  ::v-deep .el-carousel__container {
    line-height: 0;
  }
  .carousel {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 80px;
    height: 30px;
  }
  .mycarousel-indicators {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    z-index: 2;
    padding: 0 20px;
    top: 315px;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    .mycarousel-indicator {
      width: 60px;
      height: 60px;
      cursor: pointer;
      img {
        border: 1px solid #e9e9e9;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
}
#addFiles {
  ::v-deep .el-dialog {
    width: 800px;
    height: 800px;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
// .addFiles {
//   margin-left: 15px;
// }
</style>
