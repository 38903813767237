<template>
  <div class="showSlideGroup">
    <el-carousel
      arrow="never"
      :autoplay="false"
      indicator-position="none"
      height="180px"
      ref="carousel"
    >
      <el-carousel-item v-for="(group, index) in showItemGroup" :key="index">
        <div class="slideItem">
          <ShowSlideItem
            v-for="(item, itemIndex) in group"
            :key="itemIndex"
            :desc="item"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
    <button type="button" class="slide-arrow-left" @click="prev"></button>
    <button type="button" class="slide-arrow-right" @click="next"></button>
  </div>
</template>

<script>
import ShowSlideItem from "./showSlideItem/ShowSlideItem";
import { chunk } from "utils/utils";
export default {
  name: "ShowSlideGroup",
  props: {
    itemList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ShowSlideItem,
  },
  data() {
    return {
      showItemGroup: [],
    };
  },
  methods: {
    prev() {
      this.$refs["carousel"].prev();
    },
    next() {
      this.$refs["carousel"].next();
    },
  },
  created() {
    setTimeout(() => {
      this.showItemGroup = chunk(this.itemList, 5);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.showSlideGroup {
  position: relative;
  padding: 0 70px;
  .slideItem {
    @include flex-between;
  }
  .slide-arrow-left,
  .slide-arrow-right {
    @include noselect;
    position: absolute;
    width: 17px;
    height: 29px;
    top: calc(50% - 14.5px);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 17px;
      height: 29px;
      background-size: 100% 100%;
    }
    cursor: pointer;
    outline: none;
    border: none;
  }
  .slide-arrow-left {
    left: 10px;
    background: url("~assets/images/detail_slide_left.png") no-repeat;
  }
  .slide-arrow-right {
    right: 10px;
    background: url("~assets/images/detail_slide_right.png") no-repeat;
  }
}
</style>
