<template>
  <div class="specifications" :class="productType == 4 ? 'dis' : ''">
    <div
      class="group"
      v-for="(group, groupIndex) in specificationList"
      :key="groupIndex"
    >
      <div
        :class="['group-item', selectedIndex === groupIndex ? 'active' : '']"
        @click="handleSelct(group, groupIndex)"
      >
        <span class="block">{{ group.name }} </span>
        <span
          style="margin-left: 10px"
          v-if="group.title == '选择阶段流程' || group.title == '选择承办人员'"
        >
          <div
            v-if="
              group.title == '选择阶段流程' && needtime == 0 && !isShowNeedtime
            "
            class="needtime1"
          >
            <img
              width="120px"
              height="27px"
              src="../../../assets/images/Tips.png"
              alt=""
            />

            <span>
              <img
                class="Tips"
                width="11px"
                height="11px"
                src="../../../assets/images/Tips1.png"
                alt=""
              />
              <span class="Tips1">请选择阶段流程</span>
            </span>
          </div>
          <div
            v-if="
              group.title == '选择承办人员' &&
              undertaker == 1 &&
              !isShowUndertaker
            "
            class="needtime1"
          >
            <img
              width="120px"
              height="27px"
              src="../../../assets/images/Tips.png"
              alt=""
            />
            <img
              class="Tips"
              width="11px"
              height="11px"
              src="../../../assets/images/Tips1.png"
              alt=""
            />
            <span class="Tips1">请选择承办人员</span>
          </div>
        </span>
        <span
          class="select-desc"
          v-if="group.title == '选择阶段流程' || group.title == '选择承办人员'"
        >
          <div
            v-if="group.title == '选择阶段流程' && needtime != 0"
            class="needtime"
          >
            {{ needtime }}个工作时
          </div>

          <div v-if="group.title == '选择承办人员'" class="name">
            {{ undertaker.nickName }}
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    specificationList: {
      default: () => [],
    },
    productType: {},
    needtime: {
      default: 0,
    },
    undertaker: {},
    isShowNeedtime: {},
    isShowUndertaker: {},
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  mounted() {},
  methods: {
    handleSelct(item, groupIndex) {
      this.selectedIndex = groupIndex;
      this.$emit("handleSelect", item);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.dis {
  display: block !important;
}
.specifications {
  display: flex;
  flex-wrap: wrap;
  .group {
    margin: 5px 10px;
    @include flex-start;
    &-item {
      @include noselect;
      @include flex-start;
      .block {
        padding: 10px 8px;
        background: #ffffff;
        border: 1px solid #cccccc;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }

      & > .block:hover {
        border: 1px solid #eb441e;
      }
      &.active > .block {
        border: 1px solid #eb441e;
      }
      .select-desc {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.needtime1 {
  position: relative;
}
.Tips {
  position: absolute;
  left: 16px;
  margin-top: 8px;
}
.Tips1 {
  position: absolute;
  left: 29px;
  margin-top: 8px;
  font-size: 12px;
  color: #fff;
}
</style>
