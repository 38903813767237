<template>
  <div class="processSpecifications">
    <div class="group" v-if="text == 'Process'">
      <div v-for="(v, i) in processList" :key="i">
        <div
          class="group_item"
          :class="v.check ? 'selected' : ''"
          @click="handleProcessClick(v)"
        >
          {{ v.name }}
        </div>
      </div>
    </div>

    <div class="group" v-if="text == 'Area'">
      <div v-for="(v, i) in processList" :key="i">
        <div
          class="group_item"
          :class="i == AreaIndex ? 'selected' : ''"
          @click="handleAreaClick(i, v)"
        >
          {{ v.cityName }}
        </div>
      </div>
    </div>

    <div class="group" v-if="text == 'Lawyer'">
      <div v-for="(v, i) in processList" :key="i">
        <div
          class="group_item"
          :class="i == LawyerI ? 'selected' : ''"
          @click="handleLawyerClick(i, v)"
        >
          {{ v.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "processSpecifications",
  props: {
    processList: {},
    text: {},
    LawyerIndex: {},
  },
  data() {
    return {
      AreaIndex: -1,
      LawyerI: -1,
    };
  },
  watch: {
    LawyerIndex: {
      handler(val) {
        this.LawyerI = val;
      },
    },

    $route(to, from) {
      this.AreaIndex = -1;
      this.LawyerI = -1;
    },
  },
  mounted() {},
  methods: {
    handleProcessClick(val) {
      val.check = !val.check;
      this.$emit("handleProcessClick", this.processList);
    },

    handleAreaClick(i, v) {
      this.AreaIndex = i;
      //   console.log(v);
      this.$emit("handleAreaClick", v);
    },

    handleLawyerClick(i, v) {
      this.LawyerI = i;
      this.$emit("handleLawyerClick", v);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.processSpecifications {
  .group {
    display: flex;
    flex-wrap: wrap;
    .group_item {
      margin: 5px 10px;
      padding: 10px 8px;
      background-color: #fff;
      border: 1px solid #cccccc;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;

      &:hover {
        border: 1px solid #eb441e;
      }
    }

    .selected {
      border: 1px solid #eb441e;
    }
  }
}
</style>
