<template>
  <div class="showSlideItem" @click="navCopyrightPracticeDetail">
    <div class="pic">
      <img
        width="104"
        height="104"
        :src="$store.state.onlineBasePath + descInfo.productIntroduction"
        alt=""
      />
    </div>
    <div class="title">{{ descInfo.productName }}</div>
    <div class="price">
      ￥<span>{{ descInfo.productPrices }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowSlideItem",
  props: {
    desc: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      descInfo: this.desc,
    };
  },
  methods: {
    navCopyrightPracticeDetail() {
      // console.log(1);
      this.$router.push({
        name: "CopyrightPracticeDetail",
        params: {
          id: this.descInfo.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.showSlideItem {
  cursor: pointer;
  width: 104px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .pic {
    img {
      image-rendering: -webkit-optimize-contrast;
    }
  }
  .title {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #333333;
  }
  .price {
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #eb441e;
  }
}
</style>
